import React, { useEffect, useState, useCallback } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import '../css/App.css';
import 'react-toastify/dist/ReactToastify.css';
import Nav from './Nav';
import { Grid, Label, Progress, Button, Form, Segment, Message, Transition, Select, Checkbox, ButtonGroup } from 'semantic-ui-react'
import {
    APIlogout,
    getNlpTasks,
    getNlpServices,
    executeNlpAudio,
    getStats
} from '../apicalls';

const SendNlpAudio = ({ role, logincheck, loading, size }) => {

    useEffect(() => {
        if (!logincheck) {
            loading(true);
            const logOUT = async () => {
                document.cookie = 'page=;Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                await APIlogout();
                window.location.reload();
            }
            logOUT();
            loading(false);
        }
    }, [logincheck, loading]);

    const [fileLoaded, setFileLoaded] = useState(false);
    const [progressValue, setProgressValue] = useState(0)
    const [totalEntries, setTotalEntries] = useState(0)
    const [status, setStatus] = useState([]);
    const [csvResponse, setCsvResponse] = useState([""]);
    const [progressFinished, setProgressFinished] = useState('none')
    const [nlpValue, setNlpValue] = useState('gpt')
    const [resultType, setResultType] = useState('ucontact')
    const [executeValue, setExecuteValue] = useState()
    const [executeOptions, setExecuteOptions] = useState([])
    const [serviceOptions, setServiceOptions] = useState([])
    const [apiSend, setApiSend] = useState(false)
    const [outcome, setOutcome] = useState({})
    const [audioFiles, setAudioFiles] = useState();
    const [disabledActive, setDisableActive] = useState(true)
    const [internalActive, setInternalActive] = useState(true)
    const [dolbyActive, setDolbyActive] = useState(false)
    const [isComplete, setIsComplete] = useState(false)

    useEffect(() => {
        const getTasks = async () => {
            const nlpServices = await getNlpServices()

            setServiceOptions(nlpServices.map((task) => {
                return {
                    key: task.name,
                    value: task.name,
                    text: task.name
                }
            }))

            const nlpTasks = await getNlpTasks()

            setExecuteOptions(nlpTasks.map((task) => {
                return {
                    key: task.id,
                    value: task.id,
                    text: task.name
                }
            }))
            setExecuteValue(nlpTasks[0].id)
        }
        getTasks()
    }, [])

    useEffect(() => {
        if (outcome.sent === true) {
            setTimeout(() => {
                setOutcome({
                    ...outcome,
                    sent: undefined
                })
            }, 5000)
        }
    }, [outcome])

    const timeout = function (ms) {

        return new Promise(resolve => setTimeout(resolve, ms));

    }

    const loadAudioFiles = (files) => {
        const { numServices, audioServices } = getActiveServices()
        const numfile = files.length;
        setFileLoaded(true)
        setTotalEntries(numfile * numServices)
        setAudioFiles(files)
    }

    const download = function (data) {

        const blob = new Blob([data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')

        a.setAttribute('href', url)
        a.setAttribute('download', 'download.csv');
        a.click()
    }

    const getActiveServices = useCallback((index) => {
        let numServices = 0;
        let audioServices = [];
        if (disabledActive) {
            numServices++;
            audioServices.push("disabled")
        }
        if (internalActive) {
            numServices++;
            audioServices.push("internal")
        }
        if (dolbyActive) {
            numServices++;
            audioServices.push("dolby")
        }
        return {
            numServices: numServices,
            audioServices: audioServices
        }

    }, [disabledActive, dolbyActive, internalActive])

    const finalize = useCallback((csvData) => {

        document.getElementById("file-list").value = "";
        let succesful = totalEntries

        for (let singleStatus of status) {
            if (singleStatus === 'ERROR') succesful--
        }

        switch (succesful) {
            case totalEntries:
                toast.success(`${succesful}/${totalEntries} richieste inviate correttamente!`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                break;
            case 0:
                toast.error(`Errore durante l'invio delle richieste (${succesful}/${totalEntries} inviate)`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                break;
            default:
                toast.warn(`Errori durante l'invio delle richieste (${succesful}/${totalEntries} inviate)`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                break;
        }
        csvData.unshift(" ");
        let firstLine = "filename;";
        let { numServices, audioServices } = getActiveServices()
        for (let w = 0; w < numServices; w++) {
            firstLine = firstLine + audioServices[w] + ";"
        }
        csvData.unshift(firstLine);

        download(csvData.join('\n'));
        setIsComplete(false)
        setFileLoaded(false);
        setProgressValue(0);
        setTotalEntries(0);
        setAudioFiles();
        setStatus([]);
        setCsvResponse([""]);
        setProgressFinished('none');
        setOutcome({});

    }, [getActiveServices, status, totalEntries])

    const checkStatus = useCallback((execIds) => {
        // creo un array bidimensionale inizializzato a false come flag
        let complete = []
        let doneEntries = 0
        let tempCsvResp = [...csvResponse]

        //inizializzo csv
        for (let w = 0; w < audioFiles.length; w++) {
            tempCsvResp[w] = audioFiles[w].name
        }
        const interval = setInterval(async () => {
            for (let i = 0; i < execIds.length; i++) {
                for (let x = 0; x < execIds[i].length; x++) {
                    if (!complete.includes(execIds[i][x])) {
                        let response = await getStats(execIds[i][x])
                        if (response.data.status === 'archived' || response.data.status === 'ended') {
                            response.data.statusHistory.find(obj => {
                                if (obj.status === "aborted") {
                                    setOutcome({
                                        sent: true,
                                        esito: 'KO',
                                        message: "Errore NLP"
                                    })
                                    setIsComplete(true)
                                    setApiSend(false);
                                    finalize(tempCsvResp)
                                    clearInterval(interval)
                                }
                                return obj.status === "aborted"
                            })
                            tempCsvResp[x] = tempCsvResp[x] + ';' + response.data.computed
                            complete.push(execIds[i][x]);
                            doneEntries++
                            setProgressValue(doneEntries);
                            if (doneEntries === totalEntries) {
                                clearInterval(interval);
                                setProgressFinished('inline');
                                setIsComplete(true)
                                setApiSend(false);
                                finalize(tempCsvResp)

                            }
                        }

                    }

                }
            }
        }, 5000);
        return tempCsvResp

    }, [audioFiles, csvResponse, finalize, totalEntries])

    const sendApi = useCallback(async (index, audioCleanValue) => {

        let tempStatus = status;
        let execId = "";

        const body = {
            inputType: 'audio',
            audioFile: audioFiles[index],
            nlp: nlpValue,
            execute: [executeValue],
            async: '1',
            audioOptimization: audioCleanValue,
            resultType: resultType,
        }

        try {
            const response = await executeNlpAudio(body)
            if (!response) {
                return;
            }
            execId = response.data;

        } catch (err) {
            console.log(JSON.stringify(err))
            tempStatus[index] = 'ERROR'
            setStatus(tempStatus)
        }
        return execId;

    }, [status, audioFiles, nlpValue, executeValue, resultType])


    const sendMultiple = useCallback(async (event) => {
        const { numServices, audioServices } = getActiveServices()

        // controllo file e servizi
        if (!fileLoaded) {
            setOutcome({
                sent: true,
                esito: 'KO',
                message: "File non selezionato"
            })
        } else if (numServices < 1) {
            setOutcome({
                sent: true,
                esito: 'KO',
                message: "Nessun servizio selezionato"
            })
        } else {

            setApiSend(true);
            const fileEntries = audioFiles.length //numero di file audio
            const tempCsvResp = [...csvResponse]
            const tempEntries = fileEntries * numServices //numero totale chiamate per contatore avanzamento
            setTotalEntries(tempEntries)
            const maxTrials = 10;
            let execIds = []
            for (let i = 0; i < numServices; i++) {
                execIds[i] = []
                for (let x = 0; x <= fileEntries - 1; x++) {
                    execIds[i][x] = await sendApi(x, audioServices[i])
                    if (!execIds[i][x]) {
                        for (let y = 1; y <= maxTrials; y++) {
                            await timeout(6000)
                            execIds[i][x] = await sendApi(x, audioServices[i])
                            if (execIds[i][x]) break
                        }
                    }
                }
            }
            setCsvResponse(tempCsvResp)
            return execIds

        }

    }, [audioFiles, csvResponse, fileLoaded, getActiveServices, sendApi])

    const start = useCallback(async (event) => {
        let ret = await sendMultiple()
        await checkStatus(ret)
    }, [checkStatus, sendMultiple])

    return (
        <Grid>

            <Grid.Column mobile={16} tablet={16} computer={16}>
                <Nav loading={loading} size={size} role={role} />
            </Grid.Column>

            <Form
                style={{
                    maxWidth: '500px',
                    marginTop: '40px'
                }}
            >

                <Grid.Column mobile={16} tablet={16} computer={16} style={{ width: '400px' }}>
                    <ButtonGroup>
                        <Button
                            content='Carica Cartella Audio'
                            icon='folder'
                            labelPosition='right'
                            style={{ margin: '15px 0', float: 'center' }}
                            basic
                            onClick={() => { document.getElementById('uploadLbl').click() }}
                        />
                        <Button
                            content='Carica file Audio'
                            icon='paperclip'
                            labelPosition='right'
                            style={{ margin: '15px 0', float: 'center' }}
                            basic
                            onClick={() => { document.getElementById('uploadLblSingle').click() }}
                        />
                    </ButtonGroup>

                    <label id='uploadLbl' style={{ display: 'none' }} htmlFor="file-list"></label>
                    <input style={{ display: "none" }} onChange={(e) => loadAudioFiles(e.target.files)} id="file-list" directory="" webkitdirectory="" type="file"></input>
                    <label id='uploadLblSingle' style={{ display: 'none' }} htmlFor="file-list-single"></label>
                    <input style={{ display: "none" }} onChange={(e) => loadAudioFiles(e.target.files)} id="file-list-single" accept="file/*; image/*" type="file"></input>


                    <Segment
                        style={{
                            padding: '5px',
                            align: 'left'
                        }}
                    >
                        <Label color='red' attached='top left'>SERVIZIO PULIZIA AUDIO</Label>
                        <Checkbox
                            label={"Disabled"}
                            toggle
                            defaultChecked={disabledActive}
                            onChange={(_e, data) => { setDisableActive(data.checked) }}
                            style={{ padding: "7px" }}
                        /><br />
                        <Checkbox
                            label={"Internal"}
                            toggle
                            defaultChecked={internalActive}
                            onChange={(_e, data) => { setInternalActive(data.checked) }}
                            style={{ padding: "7px" }}
                        /><br />
                        <Checkbox
                            label={"Dolby"}
                            toggle
                            defaultChecked={dolbyActive}
                            onChange={(_e, data) => { setDolbyActive(data.checked) }}
                            style={{ padding: "7px" }}
                        />

                    </Segment>

                    <Segment style={{ padding: '5px' }}>
                        <Label color='teal' attached='top left'>RESULT TYPE</Label>
                        <Select
                            fluid
                            placeholder='Seleziona il tipo di risposta'
                            value={resultType}
                            options={[
                                {
                                    key: 'array',
                                    value: 'array',
                                    text: 'array'
                                },
                                {
                                    key: 'object',
                                    value: 'object',
                                    text: 'object'
                                },
                                {
                                    key: 'ucontact',
                                    value: 'ucontact',
                                    text: 'ucontact'
                                }
                            ]}
                            onChange={(_e, data) => { setResultType(data.value) }}
                        />
                    </Segment>

                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored" />

                    <Button
                        content='Invia'
                        icon='send'
                        labelPosition='right'
                        style={{ margin: '10px 0', float: 'right' }}
                        basic={!apiSend}
                        onClick={() => { start() }}
                        loading={apiSend}
                        disabled={apiSend}
                    />

                    <Button
                        content='Annulla'
                        color='red'
                        basic
                        style={{ margin: '10px 5px', float: 'right', display: apiSend ? 'block' : 'none' }}
                        onClick={() => window.location.reload()}
                    />

                    <Segment
                        style={{
                            padding: '6px',
                            marginTop: '85px'
                        }}
                    >
                        <Label attached='top'>AVANZAMENTO RICHIESTE {totalEntries ? `- ${progressValue}/${totalEntries}` : ''}</Label>

                        <Progress
                            indicating={apiSend}
                            style={{ margin: '0' }}
                            value={progressValue}
                            total={totalEntries}
                            size='small'
                        />
                    </Segment>

                </Grid.Column>

                <Grid.Column mobile={16} tablet={16} computer={16} style={{ width: '400px' }}>
                    <Transition visible={outcome.sent === true} animation='fade down' duration={500}>
                        <Message positive={outcome.esito === 'OK'} negative={outcome.esito === 'KO'}>
                            <p>{outcome.message}</p>
                        </Message>
                    </Transition>
                </Grid.Column>
            </Form>

        </Grid >
    )
}

export default SendNlpAudio;