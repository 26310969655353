import React from 'react';
import { Button } from 'semantic-ui-react'
import { navigate } from '@reach/router';

const MenuButton = ({ label, color, route }) => {

    return (
        <div>
            <Button
                fluid
                size='huge'
                style={{ margin: '8px' }}
                color={color}
                onClick={() => { navigate(route) }}
            >
                {label}
            </Button>
        </div>
    )
}

export default MenuButton;