import React from 'react';

import { APIlogout } from '../apicalls';
import { navigate } from '@reach/router';

import {
    Grid,
    Header,
    Image,
    Icon,
    Button
} from 'semantic-ui-react'

const Nav = () => {


    const logOUT = async () => {
        try {
            await APIlogout();
        }
        finally {
            window.location.reload();
            navigate('/login');
        }
    }

    return (

        <>
            <div>
                <Button
                    content='Logout'
                    icon='log out'
                    labelPosition='right'
                    style={{ margin: '10px 10px', float: 'right' }}
                    basic={true}
                    onClick={logOUT}
                />
                <Image
                    style={{ paddingTop: '20px' }}
                    size='medium'
                    src='https://inx-assets.s3.eu-south-1.amazonaws.com/inxide/Inxide-logo.png'
                    centered
                />
                <h2 style={{ marginTop: '5px', textAlign: 'center', color: '#1C2545' }}>INXIDE UTILITIES PANEL</h2>
            </div>

        </>

    )
}

export default Nav;
