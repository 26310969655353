import axios from 'axios';

//API CREATE RUN ****

export const APIcheckSignin = (async (user, pass) => {
    const risp = await axios.get('/api/auth/current-user')
    return risp;
});

export const APIsignin = (async (user, pass, group) => {
    const risp = await axios.post('/api/auth/signin',
        {
            username: `${user}`,
            password: `${pass}`,
            group: `${group}`
        }
    );
    return risp;
});

export const APIlogout = (async () => {
    const risp = axios.post('/api/auth/signout');
    return risp;
});

// CHANGE PASS

export const APIchangePass = async (inputs, setError) => { // PRENDO LE CHIAVI PER RECUPERARE GLI INPUTS
    /* console.log(inputs); */
    const api_url = "/api/auth/signin/pass-change";
    const response = await axios.post(api_url, inputs, {})

        .catch((error) => {
            console.log(error.response.statusText);
            setError({ status: error.response.status, data: error.response.statusText })
        });
    if (response) {
        // console.log(response);
        return response.data;
    } else {
        return null
    }
}

export const APIauthUC = async (setError) => { // PRENDO LE CHIAVI PER RECUPERARE GLI INPUTS
    /* console.log(inputs); */

    let body = {
        user: "admin_report",
        password: "Dacambiare1!"
    }

    const api_url = "https://inxide.ucontactcloud.com/Integra/resources/auth/getUserToken";
    const response = await axios.post(api_url, body, {

        headers: { "Content-Type": "application/x-www-form-urlencoded" },

    })

        .catch((error) => {
            console.log(error.response.statusText);
            setError({ status: error.response.status, data: error.response.statusText })
        });
    if (response) {
        // console.log(response);
        return response.data;
    } else {
        return null
    }
}

export const APIgenerateDocUC = async (token, par1, par2, setError) => {

    let s = `{ "name": "TEST OPENAI", "file": "DettaglioTentativiOpenAI.jrxml", "description": "TEST OPENAI", "dsn": "Repo", "parameters": "INITIAL_DATE=Timestamp=${par1 !== "" ? par1 : "2023-02-10"} 00:00:00;FINAL_DATE=Timestamp=${par2 !== "" ? par2 : "2023-12-31"} 23:59:59", "grouped": "TEST OPENAI", "language": "en", "license": "CCS" }`

    let body = {
        report: s,
        html: 0,
        doc: 0,
        excel: 2,
        pdf: 0
    }

    const api_url = "https://inxide.ucontactcloud.com/Integra/resources/BI/generatereport";
    const response = await axios.post(api_url, body, {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": `Basic ${token}`
        }
    })

        .catch((error) => {
            console.log(error);
            setError(error.code)
        });
    if (response) {
        // console.log(response);
        return response.data;
    } else {
        return null
    }
}


export const APIgetDocUC = async (id, setError) => { // PRENDO LE CHIAVI PER RECUPERARE GLI INPUTS

    const api_url = `https://inxide.ucontactcloud.com/tmp/${id}.csv`;
    const response = await axios.get(api_url)

        .catch((error) => {
            console.log(error);
            setError(error.code)
        });
    if (response) {

        return response.data;
    } else {
        return null
    }
}

export const getNlpTasks = async () => {
    let resp = {}
    try {
        resp = await axios.get('/api/nlp/tasks')
    } catch (err) {
        console.log(err)
        return []
    }

    return resp.data.map(item=> ({...item, id: item._id || item.id}))
}

export const getNlpServices = async () => {
    let resp = {}
    try {
        resp = await axios.get('/api/nlp/services')
    } catch (err) {
        console.log(err)
    }

    return resp.data
}

export const executeNlp = async (body, cookie) => {

    const FormData = require('form-data');

    let data = new FormData();
    data.append('inputType', body.inputType);
    data.append('nlp', body.nlp);
    
    body.execute.forEach( (ex, idx) => {
        data.append(`execute[${idx}]`, ex)
    })
    
    data.append('async', body.async);
    data.append('text', body.text);

    Object.keys(body.extraData).forEach( (exd, idx) => {
        data.append(`extraData[${exd}]`, body.extraData[exd])
    })

    body.dlrUrls.forEach( (url, idx) => {
        data.append(`dlrUrls[${idx}]`, url)
    })

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: '/api/nlp/execute',
        headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json'
        },
        data: data
    };

    const resp = await axios.request(config)
    return resp.data

}

export const executeNlpMassive = async (body, cookie) => {

    const FormData = require('form-data');
    let data = new FormData();
    data.append('inputType', body.inputType);
    data.append('nlp', body.nlp);

    body.execute.forEach( (ex, idx) => {
        data.append(`execute[${idx}]`, ex)
    })

    data.append('async', '0');
    data.append('text', body.text);
    data.append('resultType', 'ucontact');

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: '/api/nlp/execute',
        headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json'
        },
        data: data
    };
    let resp
    try {
        resp = await axios.request(config)
    }
    catch (err) {
        console.log(err)
        return undefined
    }
    return resp.data

}

export const getStats = async (execId) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: '/api/nlp/stats/'+execId,
        headers: {
            'Accept': 'application/json'
        }
    };

    let resp
    try {
        resp = await axios.request(config)
    }
    catch (err) {
        console.log(err)
        return undefined
    }
    return resp
}

export const executeNlpAudio = async (body, cookie) => {
    const FormData = require('form-data');
    let data = new FormData();

    console.log(body)
    data.append('inputType', body.inputType);
    data.append('nlp', body.nlp);
    
    body.execute.forEach( (ex, idx) => {
        data.append(`execute[${idx}]`, ex)
    })

    data.append('async', body.async);
    data.append('audioFile', body.audioFile);
    data.append('audioOptimization', body.audioOptimization)
    data.append('resultType', body.resultType);

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: '/api/nlp/execute',
        headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json'
        },
        data: data
    };

    let resp
    try {
        resp = await axios.request(config)
    }
    catch (err) {
        console.log(err)
        return undefined
    }
    return resp
}

