import React, { useEffect, useState } from 'react';
import '../css/App.css';
import Nav from './Nav';
import axios from 'axios';
import { Grid, Label, Input, Dropdown, TextArea, Button, Form, Segment, Select, Accordion, Icon, Message, Transition } from 'semantic-ui-react'
import {
    APIlogout,
    getNlpTasks,
    getNlpServices,
    executeNlp
} from '../apicalls';


const allowedTasks = [
    "get-5-top-keywords",
    "get-ekmans-general-sentiment",
    "get-personal-data",
    "get-pnn-sentiment",
    "get-ekmans-sentence-sentiment",
    "get-summary",
    "get-tree-classification"
]

const SendNlp = ({ role, logincheck, loading, size }) => {

    useEffect(() => {
        if (!logincheck) {
            loading(true);
            const logOUT = async () => {
                document.cookie = 'page=;Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                await APIlogout();
                window.location.reload();
            }
            logOUT();
            loading(false);
        }
    }, [logincheck, loading]);

    const [extraDataValue, setExtraDataValue] = useState({ nome: 'Mario Rossi', channel: 'whatsapp', cellulare: '3298980253' })
    const [textValue, setTextValue] = useState()
    const [nlpValue, setNlpValue] = useState('davinci')
    const [executeValue, setExecuteValue] = useState([])
    const [executeOptions, setExecuteOptions] = useState([])
    const [serviceOptions, setServiceOptions] = useState([])

    const [contactProp, setContactProp] = useState('whatsapp')
    const [activeAccordion, setActiveAccordion] = useState(false)
    const [apiSend, setApiSend] = useState(false)

    const [outcome, setOutcome] = useState({})

    useEffect(() => {
        const getTaks = async () => {
            const nlpServices = await getNlpServices()
            
            setServiceOptions(nlpServices.map((task) => {
                return {
                    key: task.name,
                    value: task.name,
                    text: task.name
                }
            }))

            const nlpTasks = await getNlpTasks()

            setExecuteOptions(nlpTasks.map((task) => {
                return {
                    key: task.id,
                    value: task.id,
                    text: task.name
                }
            }))
            // setExecuteValue(nlpTasks.filter(task => allowedTasks.includes(task.name)).map(task => task.id))
        }
        getTaks()
    }, [])

    const setToggleContactProp = (prop) => {
        setContactProp(prop)
        setExtraDataValue({
            ...extraDataValue,
            channel: prop
        })
    }

    useEffect(() => {
        if (outcome.sent === true) {
            setTimeout(() => {
                setOutcome({
                    ...outcome,
                    sent: undefined
                })
            }, 5000)
        }
    }, [outcome])


    const sendApi = async () => {

        setApiSend(true);

        const cmUrl = `${process.env.REACT_APP_API_URL}/api/internal/nlp/insert/${process.env.REACT_APP_WORKSPACE_ID}`

        const body = {
            inputType: 'text',
            text: textValue,
            nlp: nlpValue,
            execute: executeValue,
            dlrUrls: [ cmUrl ],
            extraData: {
                ...extraDataValue,
            },
            async: '1'
        }

        try {
            await executeNlp(body)
            setOutcome({
                sent: true,
                esito: 'OK',
                message: 'Invio effettuato con successo!'
            })
        } catch (err) {
            setOutcome({
                sent: true,
                esito: 'KO',
                message: "Errore durante l'invio del contatto."
            })
        } finally {
            setApiSend(false);
        }
    }

    return (
        <Grid>

            <Grid.Column mobile={16} tablet={16} computer={16}>
                <Nav loading={loading} size={size} role={role} />
            </Grid.Column>

            <Form
                style={{
                    maxWidth: '500px',
                    marginTop: '40px'
                }}

            >
                <Grid.Column mobile={16} tablet={16} computer={16} style={{ width: '400px' }}>

                    <Button.Group attached='top' style={{ width: '100%' }}>
                        <Button color={contactProp === 'email' ? 'orange' : null} toggle onClick={() => { setToggleContactProp('email') }} >e-mail</Button>
                        <Button.Or text=''></Button.Or>
                        <Button color={contactProp === 'whatsapp' ? 'orange' : null} toggle onClick={() => { setToggleContactProp('whatsapp') }}>whatsapp</Button>
                    </Button.Group>

                    {
                        contactProp === 'email' &&
                        <Segment attached='bottom'
                            style={{
                                padding: '5px',
                                marginTop: '0'
                            }}
                        >
                            <Input
                                style={{
                                    width: '100%'
                                }}
                                placeholder='E-mail'
                                value={extraDataValue?.email || ''}
                                onChange={(e) => {
                                    setExtraDataValue({
                                        ...extraDataValue,
                                        email: e.target.value
                                    })
                                }}
                            />
                        </Segment>
                    }

                    {
                        contactProp === 'whatsapp' &&
                        <Segment attached='bottom'
                            style={{
                                padding: '5px',
                                marginTop: '0'
                            }}
                        >
                            <Input
                                style={{
                                    width: '100%'
                                }}
                                placeholder='Telefono'
                                value={extraDataValue?.telefono || ''}
                                onChange={(e) => {
                                    setExtraDataValue({
                                        ...extraDataValue,
                                        telefono: e.target.value
                                    })
                                }}
                            />
                        </Segment>
                    }


                    <Segment
                        style={{
                            padding: '5px'
                        }}
                    >
                        <Label color='pink' attached='top left'>TESTO DEL MESSAGGIO</Label>
                        <TextArea
                            style={{
                                width: '100%'
                            }}
                            placeholder='Scrivi il testo del messaggio'
                            value={textValue}
                            onChange={(e) => { setTextValue(e.target.value) }}
                        />
                    </Segment>

                    <Accordion style={{
                        alignItems: 'flex-start',
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <Accordion.Title
                            active={activeAccordion}
                            index={0}
                            onClick={() => { setActiveAccordion(!activeAccordion) }}
                        >
                            <Icon name='dropdown' />
                            Impostazioni avanzate
                        </Accordion.Title>
                        <Transition visible={activeAccordion} animation='slide down' duration={250}>

                            <Accordion.Content active={activeAccordion} style={{ width: '100%' }}>

                                <Transition visible={activeAccordion} animation='slide down' duration={250}>
                                    <Segment
                                        style={{
                                            padding: '5px'
                                        }}
                                    >
                                        <Label color='violet' attached='top left'>NOME CLIENTE</Label>
                                        <Input
                                            style={{
                                                width: '100%'
                                            }}
                                            placeholder='Nome'
                                            value={extraDataValue?.nome || ''}
                                            onChange={(e) => {
                                                setExtraDataValue({
                                                    ...extraDataValue,
                                                    nome: e.target.value
                                                })
                                            }}
                                        />
                                    </Segment>
                                </Transition>

                                <Transition visible={activeAccordion} animation='slide down' duration={500}>
                                    <Segment
                                        style={{
                                            padding: '5px'
                                        }}
                                    >
                                        <Label color='teal' attached='top left'>SERVIZIO NLP</Label>
                                        <Select
                                            style={{
                                                width: '100%'
                                            }}
                                            placeholder='Seleziona il servizio di AI'
                                            value={nlpValue}
                                            options={serviceOptions}
                                            onChange={(_e, data) => { setNlpValue(data.value) }}
                                        />
                                    </Segment>
                                </Transition>

                                <Transition visible={activeAccordion} animation='slide down' duration={750}>
                                    <Segment
                                        style={{
                                            padding: '5px'
                                        }}
                                    >
                                        <Label color='blue' attached='top left'>TASK DA ESEGUIRE</Label>
                                        <Dropdown
                                            options={executeOptions}
                                            placeholder='Seleziona i task da eseguire'
                                            selection
                                            fluid
                                            multiple
                                            value={executeValue}
                                            onChange={(_e, data) => { setExecuteValue(data.value) }}
                                        />
                                    </Segment>
                                </Transition>

                            </Accordion.Content>

                        </Transition>
                    </Accordion>

                    <Button
                        content='Invia'
                        icon='send'
                        labelPosition='right'
                        style={{ margin: '15px 0', float: 'right' }}
                        basic
                        onClick={() => { sendApi() }}
                        loading={apiSend}
                    />

                </Grid.Column>

                <Grid.Column mobile={16} tablet={16} computer={16} style={{ width: '400px' }}>
                    <Transition visible={outcome.sent === true} animation='fade down' duration={500}>
                        <Message positive={outcome.esito === 'OK'} negative={outcome.esito === 'KO'}>
                            <p>{outcome.message}</p>
                        </Message>
                    </Transition>
                </Grid.Column>
            </Form>

        </Grid >
    )
}

export default SendNlp;